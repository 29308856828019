@font-face {
  font-family: "font-1";
  src: url(./assets/fonts/JosefinSans-ExtraLight.ttf);
}
@font-face {
  font-family: "font-2";
  src: url(./assets/fonts/JosefinSans-Regular.ttf);
}
@font-face {
  font-family: "font-3";
  src: url(./assets/fonts/JosefinSans-Bold.ttf);
}
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700&display=swap');
$font-1: 'font-1', sans-serif; /* Définition de la police */;
$font-2: 'font-2', sans-serif; /* Définition de la police */
$font-3: 'Orbitron', sans-serif; /* Définition de la police */

$jaune:#FFEB3B;
$cyan: #00FFFF;
$mangenta: #FF00FF;
$rose:#FF1493;
$vert:#39FF14;
$orange: #FF6F00;
$rouge: #FF3131;

$color-1: #FFEB3B;
$color-2: #001f3f;
$color-3: white;
$color-4: #001f3f;

$color-11: #FFEB3B;
$color-22: #001f3f;
$color-33: white;
$color-44: #F0F8FF;

$black: rgb(40, 40, 40);
$white: rgb(253, 253, 253);
$shadow: rgb(219, 218, 218);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  outline: none;
  border: none;
  text-decoration: none;

  &::selection {
    background: $color-1;
  }
}

a,
a:visited {
  color: $color-3;
}

input,
textarea {
  resize: none;
  border-radius: 20px;
  width: 270px;
  font-family: $font-1;
  background: black;
}

textarea:focus {
  box-shadow: 0 0 4px rgba(0, 22, 44, 0.1);
}

button,
input[type="submit"] {
  background: #001f3f;
  color: $color-1;
  font-family: $font-3;
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s;
  margin: 0;
  padding: 10px 20px;
  font-size: 1.1rem;
  border-radius: 20px 6px 20px 20px;

  &:hover {
    background: $color-3;
    color: $color-2;
  }
}

.button2,

.button3,
input[type="submit"] {
  background: $color-2;
  color: #FFEB3B;
  
  
  cursor: pointer;
  transition: 0.2s;
  margin: 0;
  padding: 10px 20px;
  font-size: 1.1rem;
  border-radius: 20px 6px 20px 20px;

  &:hover {
    background: #FFEB3B;
    color: #001f3f; /* Texte en bleu foncé */
    box-shadow: 0 0 15px #FFEB3B, 0 0 30px #FFEB3B, 0 0 45px #FFEB3B; /* Effet néon jaune */
    text-shadow: 0 0 8px #00d8ff, 0 0 15px #00d8ff; /* Effet néon pour le texte */
    transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
  }
}

input[type="checkbox"] {
  box-shadow: none;
  width: 20px;
  margin: 10px 0;
}

i {
  transition: 0.15s;

  &:hover {
    transform: scale(1.1);
  }
}

.error {
  color: red;
  font-weight: bold;
}

.success {
  color: green;
  font-weight: bold;
}

/* Media Queries pour les appareils mobiles */
@media (max-width: 768px) {
  input,
  textarea {
    width: 100%;
  }

  button,
  .button2,
  .button3,
  input[type="submit"] {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
  }

  .error,
  .success {
    font-size: 1rem;
  }
}


/* Media Queries pour les appareils mobiles */
@media (max-width: 768px) {
  .profil-page .log-container {
    width: 90%; /* Ajuste la largeur pour les petits écrans */
    margin: 0 auto; /* Centre l'élément */
  }

  .button2,
  .button3,
  input[type="submit"] {
    width: 100%; /* Pleine largeur pour les petits écrans */
    padding: 15px; /* Augmente le padding */
    font-size: 1rem; /* Réduit légèrement la taille de la police */
  }

  .log-container img {
    max-width: 100%; /* Redimensionne l'image pour les petits écrans */
    margin-left: 0; /* Supprime la marge */
  }

  li {
    margin-bottom: 20px; /* Réduit l'espace entre les éléments */
    padding: 20px 10px; /* Réduit le padding */
    margin-left: 10px; /* Réduit la marge gauche */
    margin-right: 10px; /* Réduit la marge droite */
  }

  .connection-form {
    font-size: 18px; /* Réduit la taille de la police */
  }

  .risk-section p {
    font-size: 0.9em; /* Réduit la taille du texte */
  }

  .img-container {
    justify-content: center; /* Centre l'image */
  }
}
