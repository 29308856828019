/* App.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700&display=swap');



.bet-value {
  color: red;
}

body {
  margin: 0;
  padding: 0;
  background-color: black;
  color: #00FFFF;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Orbitron', sans-serif; /* Définition de la police */
}

header {
  background-color: #001f3f; /* Fond noir */
  color: #ffffff; /* Texte blanc pour un bon contraste */
  padding: 10px;
  text-shadow: 0 0 8px #00d8ff, 0 0 15px #00d8ff; /* Effet néon pour le texte */
  border-bottom: 2px solid #00d8ff; /* Ligne néon au bas, bleu vibrant */
  font-family: 'Orbitron', sans-serif; /* Police futuriste */
  box-shadow: 0 4px 15px rgba(0, 216, 255, 0.75), 0 0 30px rgba(0, 216, 255, 0.6); /* Effet néon bleu adapté */
}



.admi-titre1 {
  font-size: 45px;
  text-align: center;
  color: white; /* Texte blanc pour un bon contraste */
  text-shadow: 0 0 15px #00d8ff, 0 0 50px #00d8ff; /* Effet néon pour le texte */
  font-weight: bold;
  margin-bottom: 60px;
  margin-top: 30px;
}

.admi-titre2 {
  font-size: 33px;
  text-align: center;
  color: white; /* Texte blanc pour un bon contraste */
  text-shadow: 0 0 15px #00d8ff, 0 0 50px #00d8ff; /* Effet néon pour le texte */
 
  margin-bottom: 60px;
  margin-top: 30px;
}


.connexion-titre1 {
  font-size: 45px;
  text-align: center;
  color: #FF00FF; /* Texte blanc pour un bon contraste */
  text-shadow: 0 0 15px #FF00FF, 0 0 50px #FF00FF; /* Effet néon pour le texte */
  font-weight: bold;
  margin-bottom: 60px;
  margin-top: 30px;

}

.connexion-infos {
  font-size: 30px;
  text-align: center;
  color: white; /* Texte blanc pour un bon contraste */
  text-shadow: 0 0 15px #FF00FF, 0 0 50px #FF00FF; /* Effet néon pour le texte */
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;

}

.model-titre1 {
  color: #39FF14; /* Texte blanc pour un bon contraste */
  text-shadow: 0 0 60px #39FF14, 0 0 120px #39FF14; /* Effet néon pour le texte */
  font-weight: bold;
  margin-bottom: 60px;

}
.model-titre2 {
  color: white; /* Texte blanc pour un bon contraste */
  text-shadow: 0 0 15px #39FF14, 0 0 50px #39FF14; /* Effet néon pour le texte */
  text-align: center;
  margin-top: 120px;
  margin-bottom: 30px;
}


.custom-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.custom-link-container a,
.custom-link-container button {
  background-color: #001f3f; /* Couleur de fond */
  color: white; /* Couleur du texte */
  border: 3px solid #FF00FF; /* Bordure blanche */
  box-shadow: 0 4px 15px #FF00FF, 0 0 30px #FF00FF; /* Effet néon bleu adapté */
  text-shadow: 0 0 15px #FF00FF, 0 0 50px #FF00FF; /* Effet néon pour le texte */
  padding: 10px 20px; /* Espacement intérieur */
  text-align: center;
  text-decoration: none;
  font-family: 'Orbitron', sans-serif;
  margin-bottom: 20px; /* Espacement entre les boutons */
  width: 100%; /* Occuper toute la largeur */
  max-width: 300px; /* Limiter la largeur maximale */
  border-radius: 60px; /* Coins arrondis */
  transition: background-color 0.3s ease;
  margin-bottom: 60px;
  font-weight: bold;
  font-size: 18px;
}

.custom-link-container a:hover,
.custom-link-container button:hover {
  background-color: #FFEB3B; /* Couleur au survol */
  border: 2px solid #FFEB3B; /* Bordure blanche */
  box-shadow: 0 4px 15px #FFEB3B, 0 0 30px #FFEB3B; /* Effet néon bleu adapté */
  color:#001f3f
}

.custom-link-container button {
  cursor: pointer;
}



.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}


.container {
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
}

.app-container {
  margin: 0;
  padding: 0;
}

footer {
  background-color: #0C2340;
  color: white;
  padding: 10px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.tennis-ball {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #ff0000; /* couleur rouge pour la balle de tennis */
  border-radius: 50%;
  margin-left: 5px;
  position: relative;
  animation: tennisBallAnimation 1s infinite alternate;
}

@keyframes tennisBallAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(10px); /* distance de déplacement de la balle */
  }
}

.dashboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: white; /* Couleur du texte */
  align-items: stretch; /* S'assurer que les éléments enfants prennent la même hauteur */
}

.widget {
  background-color: #001f3f; /* Fond noir */
  color: #ffffff; /* Texte blanc pour un bon contraste */
  padding: 10px;
  text-shadow: 0 0 8px #00d8ff, 0 0 15px #00d8ff; /* Effet néon pour le texte */
  border: 2px solid #00d8ff; 
  font-family: 'Orbitron', sans-serif; /* Police futuriste */
  box-shadow: 0 4px 15px rgba(0, 216, 255, 0.75), 0 0 30px rgba(0, 216, 255, 0.6); /* Effet néon bleu adapté */
  border-radius: 10px;
  flex: 1; /* Permet à chaque widget de prendre une part égale de l'espace disponible */
}

.widget h2 {
  margin-top: 0;
}

.widget-content p {
  margin: 20px;
}

.image-container {
  width: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; /* Centrer verticalement */
  height: auto; /* Laisser l'image déterminer la hauteur en fonction du contenu */
  flex: 1; /* Permettre à l'image de prendre une part égale de l'espace disponible */
}

.chatbot-image {
  width: 100%; /* Assurer que l'image prend toute la largeur disponible */
  height: 100%; /* Assurer que l'image prend toute la hauteur disponible */
  border-radius: 20px; /* Coins plus arrondis pour imiter l'effet écran TV */
  object-fit: cover; /* Ajuster l'image sans la déformer */
  
  /* Effet de bordure rétro-futuriste */
  border: 10px solid #333333; /* Cadre épais sombre pour l'effet TV */
  background-color: #000; /* Fond noir autour de l'image */
  padding: 5px;
  
  /* Effet néon autour de l'image */
  box-shadow: 
    0 0 15px #00d8ff, /* Effet de néon bleuté */
    0 0 30px #00d8ff,
    0 0 45px #00d8ff,
    inset 0 0 10px rgba(255, 255, 255, 0.2); /* Effet de lumière interne */
  
  /* Simuler les "scanlines" des vieilles TV */
  position: relative;
  overflow: hidden;
}

.chatbot-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    transparent 0px,
    transparent 3px,
    rgba(0, 0, 0, 0.2) 4px
  ); /* Lignes horizontales semi-transparentes pour l'effet de balayage */
  pointer-events: none; /* Empêche l'interaction avec les lignes */
}

.chatbot-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.15), transparent 60%);
  pointer-events: none; /* Empêche l'interaction avec cet effet */
  opacity: 0.3; /* Effet de brillance en plein milieu pour l'effet TV */
}


.ticket-torn-edge::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-top: 45px solid transparent; /* La hauteur de la déchirure */
  border-bottom: 45px solid transparent; /* La hauteur de la déchirure */
  border-left: 20px solid #0C2340; /* Couleur du ticket */
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.slogan-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  animation: fadeIn 2s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.slogan {
  font-size: 50px;
  font-family: 'Poppins', sans-serif;
  margin: 20px; /* Espacement autour du texte */
  color: #0C2340; /* Utilise $color-2 */
}

.slogan-image {
  width: 50px; /* Ajustez la taille selon vos besoins */
  height: 50px; /* Ajustez la taille selon vos besoins */
  margin: 0 10px; /* Espacement entre l'image et le texte */
}

/* Styles de base pour les liens de navigation */
.nav-link {
  margin-right: 15px; /* Ajoute un espace entre les liens */
  font-weight: normal;
  color: white; /* Couleur de base pour tous les liens non actifs */
  text-decoration: none; /* Retire le soulignement des liens */
  transition: color 0.3s, font-weight 0.3s; /* Transition douce pour la couleur et le poids de la police */
  font-size: 20px;
}

/* Styles pour le survol */
.nav-link:hover {
  color: #FFEB3B; /* Couleur jaune lors du survol */
  font-weight: bold; /* Rend le texte en gras lors du survol */
}

/* Styles pour le lien lorsqu'il est actif */
.nav-link-active, .nav-link-active:hover {
  font-weight: bold;
  color: #FFEB3B; /* Assure que la couleur jaune reste même lors du survol */
}

/* Media Queries pour les appareils mobiles */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .nav-link {
    margin-right: 0;
    margin-bottom: 10px;
    font-size: 20px; /* Ajustement pour une meilleure lisibilité */
  }

  .widget {
    width: 100%;
    padding: 15px; /* Légèrement plus grand que précédemment */
  }

  .slogan {
    font-size: 35px; /* Un peu plus grand pour mobile */
    margin: 15px;
  }

  .slogan-container {
    flex-direction: column;
    align-items: center;
  }

  .image-container {
    width: 100%;
  }

  .chatbot-image {
    width: 85%; /* Légèrement plus grand que précédemment */
  }
}

.dashboard {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.widgets {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 70%;
}

.widget {
  margin-bottom: 20px;
}

h2 {
  font-weight: bold;
  font-size: 30px;
}

.widget-content {
  font-size: 22px;
}

.widget-content span {
  width: 200px;
  display: inline-block;
}

.image-container {
  margin-left: 60px;
  flex: 1 1 auto;
}

.chatbot-image {
  margin-left: 30px;
}

/* Media Queries pour les appareils mobiles */
@media screen and (max-width: 768px) {
  .dashboard {
    flex-wrap: wrap;
  }

  .widgets {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .image-container {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media screen and (max-width: 480px) {
  h2 {
    font-size: 26px; /* Légèrement plus grand que précédemment */
  }

  .widget-content {
    font-size: 20px; /* Légèrement plus grand que précédemment */
  }

  .widget-content span {
    width: 160px; /* Légèrement plus grand que précédemment */
  }

  .image-container {
    margin-left: 0;
  }

  .chatbot-image {
    margin-left: 0;
  }
}

@media only screen and (max-width: 600px) {
  .profil-page .connection-form {
    font-size: 20px; /* Légèrement plus grand pour une meilleure lisibilité */
  }

  .profil-page .log-container .img-container img {
    max-width: 100%;
    max-height: 100%;
    margin-left: 0; /* Alignement à gauche sur les mobiles */
  }

  li {
    margin-left: 0; /* Enlever les marges sur mobile */
    margin-right: 0;
    padding: 15px;
  }

  li p {
    font-size: 18px; /* Ajuster la taille de la police pour mobile */
    margin-left: 0;
    text-align: center;
  }

  .button2 {
    font-size: 16px; /* Légèrement plus grand pour une meilleure lisibilité */
    padding: 20px 14px; /* Taille un peu plus grande pour les boutons */
  }

  .bet-input {
    width: 90px; /* Légèrement plus large que précédemment */
    font-size: 16px;
  }

  input[type="number"] {
    width: 70px; /* Légèrement plus large que précédemment */
    font-size: 20px;
    padding: 20px; /* Un peu plus d'espace dans les champs de saisie */
  }
}
